body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Add these styles to your CSS */
.dashboard__table__row:nth-child(even) {
  background-color: #f2f2f2; /* Change this color to your desired background color */
}

.dashboard__table__row:nth-child(odd) {
  background-color: #ffffff; /* Change this color to your desired background color */
}